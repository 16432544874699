import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";
import {GlobalService} from "../../shared/services/global.service";
import {AuthService} from "../../shared/services/auth.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public show: boolean = false
    submittedLogin: any = false;
    isBusy: any = false;

    constructor(private fb: FormBuilder,
                public router: Router,
                private globalService: GlobalService,
                private authService: AuthService) {
        this.checkLogin();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.loginForm = this.fb.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
        });
    }

    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.reloadSelf();
        } else {
            this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            this.router.navigateByUrl('/dashboard');
        }, e => {
            StorageService.clearAll();
            this.router.navigateByUrl('auth/login');
        });
    }

    login() {
        this.submittedLogin = true;
        if (!this.loginForm.valid) {
            return;
        }
        if (this.submittedLogin) {
            if (this.checkLoggedInStatus()) {
                this.setSelfData();
                this.router.navigateByUrl('/dashboard');
            } else {
                this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                    if (data.id) {
                        this.globalService.setAccessToken(data.token);
                        this.globalService.setSelf({
                            'name': data.name,
                            'email': data.email,
                            'contact': data.contact,
                            'user_id': data.id,
                            'username': data.username,
                            'user_type': data.user_type
                        });
                        this.router.navigateByUrl('/dashboard');
                        this.loginForm.reset();
                    }
                }, error => {
                    this.isBusy = false;
                    this.submittedLogin = false;
                });
            }
        }
    }

    showPassword() {
        this.show = !this.show
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }
}
