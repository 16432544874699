<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
    <div class="header-wrapper row m-0">
        <app-search></app-search>
        <div class="header-logo-wrapper col-auto p-0">
            <div class="logo-wrapper">
                <a routerLink="/">
                    <img class="img-fluid for-light" src="assets/images/bui.svg" alt=""/>
                    <img class="img-fluid for-dark" src="assets/images/bui.svg" alt=""/>
                </a>
            </div>
            <div class="toggle-sidebar" (click)="sidebarToggle()">
                <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
            </div>
        </div>
        <!--<div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
            <app-swiper-old></app-swiper-old>
        </div>-->
        <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
            <ul class="nav-menus">
                <!--<li class="language-nav" (click)="languageToggle()">
                    <app-languages></app-languages>
                </li>
                <li>
                  <span class="header-search" (click)="searchToggle()">
                    <app-svg-icon [icon]="'search'"></app-svg-icon>
                  </span>
                </li>
                <li class="onhover-dropdown">
                    <app-bookmark></app-bookmark>
                </li>
                <li>
                    <div class="mode" (click)="layoutToggle()">
                        <svg>
                            <use href="assets/svg/icon-sprite.svg#moon"></use>
                        </svg>
                    </div>
                </li>
                <li class="cart-nav onhover-dropdown">
                    <app-cart></app-cart>
                </li>
                <li class="onhover-dropdown">
                    <app-notification></app-notification>
                </li>-->
                <li class="profile-nav onhover-dropdown p-0">
                    <app-my-account></app-my-account>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
