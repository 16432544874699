import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceReplace'
})
export class SpaceReplacePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/_/g, ' ');
    }
    return value;
  }
}
