<!-- footer start-->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">
                Copyright {{ today | date:'y'}} ©
                <span class="text-info cursor-pointer" (click)="open('https://www.volmint.com')">BIU.</span>
                All rights reserved.
            </p>
        </div>
    </div>
</div>
