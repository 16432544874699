import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {ContactService} from "../../shared/services/contact.service";
import {StorageService} from "../../shared/services/storage.service";

@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.scss']
})
export class ApplyNowComponent implements  OnInit{
    searchForm: FormGroup;
    submitting: any = false;
    careers: any = [];
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    closeResult = '';
    constructor(private modalService: NgbModal,
                private router: Router,
                private contactusService: ContactService,
                private fb: FormBuilder) {
        this.user = StorageService.getItem('self');
        // if (this.user.user_type === 'ADMIN' || this.user.user_type === 'HEAD_COUNSELLOR' || this.user.user_type === 'COUNSELLOR') {
        //     this.router.navigate(['/dashboard']);
        // }
    }

    ngOnInit() {
        this.refresh();
    }
    refresh() {
        this.get();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.status = 'ACTIVE';
        this.contactusService.applyNow(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.careers = data['data'];
            } else {
                this.careers = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        }, error => {
            this.careers = [];
        });
    }



    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
        };
        this.get(params);
    }


}
