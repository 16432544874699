import {Routes} from "@angular/router";

export const content: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)
    },
    {
        path: "contact-us",
        loadChildren: () => import("../../components/contact-us/contact-us.module").then((m) => m.ContactUsModule)
    },
    {
        path: "apply-now",
        loadChildren: () => import("../../components/apply-now/apply-now.module").then((m) => m.ApplyNowModule)
    }
];
