<app-breadcrumb [active_item]="'Apply Data'" [icon]="'fa-user-shield fas'" [title]="'Apply Data'"
                [url]="'/apply-now'"></app-breadcrumb>
<div class="container-fluid">

    <div class="row">
        <div class="col-sm-12">
            <div class="card" style="border: none!important;">
                <div class="card-header p-25px">
                    <div class="row">
                        <div class="col-12">
                            <h5 style="color: #0c5460">Apply Now Candiate</h5>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive" style="overflow-x: auto;">
                            <table class="table text-center">
                                <thead class="thead-light .bg-*" style="background-color: #f6f8fa">
                                <tr>
                                    <th class="text-center" scope="col">S.NO.</th>
                                    <th class="text-center" scope="col">Name</th>
                                    <th class="text-center" scope="col">Contact</th>
                                    <th class="text-center" scope="col">Email</th>
                                    <th class="text-center" scope="col">Course</th>
                                    <th class="text-center" scope="col" style="width: 10rem;">ACTION</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="careers && careers.length > 0; else noDataToShow">
                                <tr *ngFor="let career of careers;  let i = index">
                                    <td class="text-center">
                                        {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        .
                                    </td>
                                    <td class="text-center">{{career?.name}}</td>
                                    <td class="text-center">{{career?.contact}}</td>
                                    <td class="text-center">{{career?.email}}</td>
                                    <td class="text-center text-capitalize">{{career?.course|spaceReplace}}</td>
                                    <td class="text-center">{{ career?.created_at | dateFormat }}</td>
                                </tr>
                                </tbody>
                                <ng-template #noDataToShow>
                                    <tbody>
                                    <tr>
                                        <td class="text-center" colspan="9">
                                            <span>No data to show</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="pagination-top">
                        <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                        [boundaryLinks]="true"
                                        [collectionSize]="pagination.total"
                                        [maxSize]="5"
                                        [pageSize]="pagination.perpage"
                                        class="d-flex justify-content-center">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

