import {CommonModule, NgOptimizedImage} from "@angular/common";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {DragulaModule} from "ng2-dragula";
import {TranslateModule} from "@ngx-translate/core";
// Components
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {FeatherIconsComponent} from "./components/feather-icons/feather-icons.component";
import {FooterComponent} from "./components/footer/footer.component";
import {HeaderComponent} from "./components/header/header.component";
import {ContentComponent} from "./components/layout/content/content.component";
import {FullComponent} from "./components/layout/full/full.component";
import {LoaderComponent} from "./components/loader/loader.component";
import {SidebarComponent} from "./components/sidebar/sidebar.component";
import {TapToTopComponent} from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import {SearchComponent} from "./components/header/elements/search/search.component";
import {LanguagesComponent} from "./components/header/elements/languages/languages.component";
import {NotificationComponent} from "./components/header/elements/notification/notification.component";
import {BookmarkComponent} from "./components/header/elements/bookmark/bookmark.component";
import {CartComponent} from "./components/header/elements/cart/cart.component";
import {MessageBoxComponent} from "./components/header/elements/message-box/message-box.component";
import {MyAccountComponent} from "./components/header/elements/my-account/my-account.component";

// Services
import {LayoutService} from "./services/layout.service";
import {NavService} from "./services/nav.service";
import {DecimalPipe} from "@angular/common";
import {SvgIconComponent} from "./components/svg-icon/svg-icon.component";
import {CarouselModule} from "ngx-owl-carousel-o";
import {SwiperModule} from "swiper/angular";
import {IndianNumberPipe} from "./pipes/indian-number.pipe";
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SpaceReplacePipe } from './pipes/space-replace.pipe';

//import {SwiperComponent} from "./components/header/elements/swiper-old";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ContentComponent,
        BreadcrumbComponent,
        FeatherIconsComponent,
        FullComponent,
        LoaderComponent,
        TapToTopComponent,
        SearchComponent,
        LanguagesComponent,
        NotificationComponent,
        BookmarkComponent,
        CartComponent,
        MessageBoxComponent,
        MyAccountComponent,
        SvgIconComponent,
        IndianNumberPipe,
        DateFormatPipe,
        SpaceReplacePipe
        //SwiperComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DragulaModule.forRoot(),
        TranslateModule.forRoot(),
        CarouselModule,
        SwiperModule,
        NgOptimizedImage
    ],
    providers: [
        NavService,
        LayoutService,
        DecimalPipe
    ],
    exports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LoaderComponent,
        BreadcrumbComponent,
        FeatherIconsComponent,
        TapToTopComponent,
        SvgIconComponent,
        SwiperModule,
        IndianNumberPipe,
        DateFormatPipe,
        SpaceReplacePipe
    ],
})
export class SharedModule {
}
