import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApplyNowComponent} from './apply-now.component';
import {SharedModule} from "../../shared/shared.module";
import {ApplyNowRoutingModule} from "./apply-now-routing.module";



@NgModule({
    declarations: [
        ApplyNowComponent,
    ],
    imports: [
        CommonModule,
        ApplyNowRoutingModule,
        SharedModule
    ]
})
export class ApplyNowModule {
}
